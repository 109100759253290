import { default as Axios} from "axios";

export class RestService {
    
    postMethod(url: string, data: object | string, header: object) {
        const promise = Axios.post(url, data, { headers: header});

        return promise.then((res) => {
            return res.data ? res.data : res;
        }).catch((error) => {
            return error;
        })
    }


    getMethod(url: string, header: object) {
        const promise = Axios.get(url, { headers: header});

        return promise.then((res) => {
            return res.data ? res.data : res;
        }).catch((error) => {
            return error;
        })
    }


}