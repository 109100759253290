import { RestService } from './RestService';
import store from '../reduxState/store';

const apiUrl = process.env.REACT_APP_API_URL;
const state = store.getState();

export const Canteen = (shift: number) => {
    const token = state.user.user_token
    const headerData = {'Authorization': `Bearer ${token}`};
    const params = {
        "shift": shift,
    };
    return new RestService().postMethod(apiUrl + 'check-canteen', params, headerData); 
};