import HomePage from "./pages/Home/HomePage";
import NotFoundPage from "./pages/Auth/NotFoundPage";
import OrderPage from "./pages/Home/OrderPage";

const routes = [
  { exact:true, path: "/home", component: HomePage },
  { exact:false, path: "/order", component: OrderPage },
  { exact:false, component: NotFoundPage }
];

export default routes;
