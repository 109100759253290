export const GENERAL_SERVICE_ERROR = 'Desila se greška prilikom povezivanja na servis. Pokušajte kasnije';
export const GENERAL_BLOCK_NUMBER = 'Broj blokova';
export const GENERAL_NOT_FOUND = 'Stranica nije pronađena';
export const GENERAL_NOT_FOUND_DESCRIPTION = 'Osvježite stranicu kako bi krenuli ispočetka';
export const GENERAL_SUCCESS_ORDER = 'Uspješno ste poručili: ';
export const GENERAL_SUCCESS_ORDER_TOMORROW = 'Uspješno ste uradili narudžbu';

export const GENERAL_SERVICE_OFF = 'Servis "Narudžba za danas" je dostupan samo u periodu od ';
export const GENERAL_SERVICE_PURCHASE_OFF = 'Već ste izvršili jednu narudžbu u ovoj smjeni';

export const RELOAD = 'Osvježit';
export const EXIT = 'Izlazak';
export const JUMP = 'Idi dalje';
export const JUMP_CLOSE = 'Završi bez narudžbe';

export const NEXT = 'Naredni dan';
export const BACK = 'Nazad';

export const SHIFT = 'Smjena';

export const CANTEEN = 'SUHA HRANA';
export const SUBSTITUTE_MEAL = 'Zamjensko jelo';

//LoginPage
export const LOGIN_PRIMARY = 'DOBRO DOŠLI';
export const LOGIN_DESCRIPTION = 'Skenirajte Vašu karticu kako bi započeli';

//HomePage
export const HOME_PRIMARY = 'Odaberi jelo';

//OrderPage
export const ORDER_PRIMARY = 'Narudžba za ';
export const ORDER_SHIFT_ALERT = 'U ovoj smjeni Vas očekuje suhi obrok';

//SelectOrderPage
export const SELECT_ORDER_PRIMARY = 'Narudžba';
export const SELECT_ORDER_SECONDARY = 'Odaberite datum i smjenu za koji želite izvršiti narudžbu';
export const SELECT_ORDER_DATE_DEFAULT = 'Odaberite datum';
export const SELECT_ORDER_SHIFT = 'SMJENA';
export const SELECT_ORDER_ERROR = 'Izaberite datum i smijenu za koju želite napraviti narudžbu';
export const SELECT_SHIFT = 'Odaberite smjenu';