export const addUserToken = (userToken: string) => (
    {
      type: 'ADD_USER_TOKEN',
      payload: userToken,
    }
);

export const addUserName = (userName: string) => (
  {
    type: 'ADD_USER_NAME',
    payload: userName,
  }
);

export const addUserImage = (userImage: string) => (
  {
    type: 'ADD_USER_IMAGE',
    payload: userImage,
  }
);

export const addUserVaucher = (userVaucher: number) => (
  {
    type: 'ADD_USER_VAUCHER',
    payload: userVaucher,
  }
);

export const addUserPurchaseStatus = (userPurchaseStatus: Object) => (
  {
    type: 'ADD_USER_PURCHASE_STATUS',
    payload: userPurchaseStatus,
  }
);

export const addUserShift = (userShift: number) => (
  {
    type: 'ADD_USER_SHIFT',
    payload: userShift,
  }
);

export const addUserTypeOfWork = (userTypeOfWork: number) => (
  {
    type: 'ADD_USER_TYPE_OF_WORK',
    payload: userTypeOfWork,
  }
);

/* -------------------------------------------------------------- */

export const addSystemAvailable = (systemAvailable: Object ) => (
  {
    type: 'ADD_SYSTEM_AVAILABLE',
    payload: systemAvailable,
  }
);

/* -------------------------------------------------------------- */

export const toggleLoggedIn = (isLoggedIn: boolean) => (
    {
      type: 'TOGGLE_LOGGED_IN',
      payload: isLoggedIn,
    }
);