import { Alert } from 'reactstrap';

interface IRequestAlertProps {
    type?: string,
    text?: string,
}

function RequestAlert(props:IRequestAlertProps) {
    return (
        <Alert color={props.type ? props.type : "light"} className="text-center my-2">
            {props.text}
        </Alert>
    );
  }

export default RequestAlert;
