import { RestService } from './RestService';

const apiUrl = process.env.REACT_APP_API_URL;

export const Login = (cardNumber: number) => {
    const params = {
        "card_number": cardNumber,
    };

    const headerData = {'Content-Type': 'application/json'};

    return new RestService().postMethod(apiUrl + 'card-number', params, headerData); 
    
};