import { combineReducers } from 'redux';

const INITIAL_STATE = {
    system_available: {
        status: "",
        time_of_day: null,
        msg: ""
    },
    is_logged_in: false,
    user_name: '',
    user_image: '',
    user_vaucher: 0,
    user_token: '',
    user_purchase_status: {
        status: "",
        code: null,
        msg: ""
    },
    user_shift: null,
    user_type_of_work: null
}

const appReducer = (state = INITIAL_STATE, action: any) => {
    let current = state;
    switch(action.type) {
        case 'ADD_SYSTEM_AVAILABLE':
            current.system_available = action.payload;
            return current;
        default:
            return state;
    }
};

const userReducer = (state = INITIAL_STATE, action: any) => {
    let current = state;
    switch(action.type) {
        case 'ADD_USER_TOKEN':
            current.user_token = action.payload;
            return current;
        case 'ADD_USER_NAME':
            current.user_name = action.payload;
            return current;
        case 'ADD_USER_IMAGE':
            current.user_image = action.payload;
            return current;
        case 'ADD_USER_VAUCHER':
            current.user_vaucher = action.payload;
            return current;
        case 'ADD_USER_PURCHASE_STATUS':
            current.user_purchase_status = action.payload;
            return current; 
        case 'ADD_USER_SHIFT':
            current.user_shift = action.payload;
            return current;
        case 'ADD_USER_TYPE_OF_WORK':
            current.user_type_of_work = action.payload;
            return current;
        case 'TOGGLE_LOGGED_IN':
            current.is_logged_in = action.payload;
            return current; 
        default:
            return state;
    }
};

export default combineReducers({
    user: userReducer,
    app: appReducer
})