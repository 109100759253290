import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import * as Lang from '../lang';

interface IUserState {
  user: IUser,
}

interface IUser {
  is_logged_in: string,
  user_name: string,
  user_vaucher: number, 
  user_image: string,
}

function Header() {
  const user = useSelector((state:IUserState) => state.user)
  const appUrl = process.env.REACT_APP_URL;

  const profileDisplay = () => {
    if(user.is_logged_in) {
      return (
        <>
          <div className="text-profile float-start">
            <div className="username pe-3 mt-2"><h3 className="mb-0">{user.user_name}</h3></div>
            <div className="block-num pe-3">{Lang.GENERAL_BLOCK_NUMBER}: <b>{user.user_vaucher}</b></div>
          </div>
          <div className="img-profile float-end">
            <div className="profile-img">
              <img alt={user.user_name} src={`${appUrl}${user.user_image}`} />
            </div>
          </div>
        </>
      )
    }
  }
    return (
        <div className="header">
        <Row>
          <Col xs="4" className="justify-content-center align-self-center">
              <a className="logo d-block" href="/">
                <img alt="logo" src="./img/logo.png" />
              </a>
          </Col>
          <Col xs="8" className="justify-content-center align-self-center text-end">
            <div className="profile">
              {profileDisplay()}
            </div>
          </Col>
        </Row>
      </div>
    );
  }


export default Header;
