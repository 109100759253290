import React, { useState, useEffect} from 'react';
import store from '../../reduxState/store';
import { addUserVaucher } from '../../reduxState/action';
import { useSelector } from 'react-redux';
import Header from "../../components/Header";
import FoodButton from '../../components/FoodButton';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { TodaysOrder } from '../../services/TodaysOrder';
import { Canteen } from '../../services/Canteen';
import { PayOrder } from '../../services/PayOrder';
import { PayCanteen } from '../../services/PayCanteen';
import { useHistory } from "react-router-dom";
import RequestAlert from '../../components/RequestAlert';
import * as Lang from '../../lang';
import moment from 'moment';
import 'moment/locale/bs';

interface IAppState {
  app: {
    voucher_sum: number,
    user_purchase_status: {
      status: string,
      msg: string
    },
    system_available: {
      status: string,
      msg: string
    },
    user_shift: number,
    user_type_of_work: number
  },
}

function HomePage() {
    moment.locale('bs'); //lang set for moment

    const [mounted, setMounted] = useState(true)

    const history = useHistory();
    const app = useSelector((state:IAppState) => state.app)
    const [todaysOrder, setTodaysOrder] = useState({ status:"failed", order_id: 0, type_id: 0, name: "", image: "" });
    const [canteen, setCanteen] = useState({ status:"failed", name: Lang.CANTEEN });
    const [alert, setAlert] = useState({ active: false, text: "", type: "" });

    useEffect(() => {
      if(app.system_available.status === "failed") {
        doAlert(true, app.system_available.msg, "danger", true)
      } else if(app.user_purchase_status.status === "failed") {
        doAlert(true, app.user_purchase_status.msg, "danger", false)
      } else {
        getTodaysOrder();
        getCanteen(app.user_shift);
      } 
      return () => { setMounted(false) }
      // eslint-disable-next-line
    }, [])


  const getNextPage = () => {
    if (app.user_type_of_work === 1 || app.user_type_of_work === 4)
    {
      return history.push({ pathname: "/order", state: { date: 1, shift: 1 } }) //send props to order screen (date 1 tomorrow, force shift 1)
    } else {
      return history.push({ pathname: "/order", state: { date: 1, shift: 0 } })
    }
  }
    
    const getTodaysOrder = () => {
      TodaysOrder().then(res => {
        if (res.status === 'success' && mounted) {
          setTodaysOrder({ status:res.status, order_id: res.order_id, type_id: res.type_id, name: res.name, image: res.image })
        } else if(res.status === 'failed') {
          //doAlert(true, res.error_msg, "danger", "todaysOrder") - nema potrebe za alertom kad korisnik nije poručio jelo
        } else {
          doAlert(true, Lang.GENERAL_SERVICE_ERROR, "danger", true)
        }
      });
    }

    const getCanteen = (shift: number) => {
      Canteen(shift).then(res => {
        if (res.status === 'success' && mounted) {
          setCanteen({ ...canteen, status:res.status})
        } else if(res.status === 'failed') {
          //doAlert(true, res.error_msg, "danger", false) - nema potrebe za alertom
        } else {
          doAlert(true, Lang.GENERAL_SERVICE_ERROR, "danger", true)
        }
      });
    }

    const goPayOrder = (order_id:number, type_id: number, name: string, create_order: boolean) => {
      setTodaysOrder({...todaysOrder, status:"failed"})
      setCanteen({...canteen, status:"failed"})
      
      PayOrder({order_id:order_id, type_id:type_id, create_order:create_order}).then(res => {
        if (res.status === 'success') {
          doAlert(true, `${Lang.GENERAL_SUCCESS_ORDER} ${name}`, "success", false)
          store.dispatch(addUserVaucher(res.voucher_sum))
          setTimeout( //redirektuj ga nakon 3 sekunde
            () => getNextPage(),
            5000
          )
        } else {
          doAlert(true, Lang.GENERAL_SERVICE_ERROR, "danger", true)
          setTimeout( //resetuj alert nakon 3 sekunde
            () => window.location.href = "/",
            5000
          )
        }
      });
    }

    const goPayCanteen = (name: string) => {
      setTodaysOrder({...todaysOrder, status:"failed"})
      setCanteen({...canteen, status:"failed"})
      
      PayCanteen().then(res => {
        if (res.status === 'success') {
          doAlert(true, `${Lang.GENERAL_SUCCESS_ORDER} ${name}`, "success", false)
          store.dispatch(addUserVaucher(res.voucher_sum))
          setTimeout( //redirektuj ga nakon 3 sekunde
            () => getNextPage(),
            5000
          )
        } else {
          doAlert(true, Lang.GENERAL_SERVICE_ERROR, "danger", true)
          setTimeout( //resetuj alert nakon 3 sekunde
            () => window.location.href = "/",
            5000
          )
        }
      });
    }

    const buttons = () => {
      if(app.voucher_sum !== 0) return (
        <>
          <FoodButton status={todaysOrder.status} col="12" image={true} name={todaysOrder.name} image_file={todaysOrder.image} action={() => goPayOrder(todaysOrder.order_id, todaysOrder.type_id, todaysOrder.name, false)} />
          <FoodButton status={canteen.status} col="12" image={false} name={Lang.CANTEEN} image_file={""} action={() => goPayCanteen(Lang.CANTEEN)} />
        </>
      )
    }

    const guestButton = () => {
      if(app.voucher_sum !== 0 && app.user_type_of_work === 4 && todaysOrder.status === 'failed' && app.user_purchase_status.status !== "failed") {
        return (
          <FoodButton status={'success'} col="12" image={false} name={Lang.SUBSTITUTE_MEAL} image_file={""} action={() => goPayOrder(0, 2, Lang.SUBSTITUTE_MEAL, true)} />
        )
      }
    }

    const doAlert = (active: boolean, text: string, type: string, reload: boolean) => {
      if(!reload) {
        setAlert({active: active, text: text, type: type}) //setuj alert
      } else {
        setAlert({active: active, text: text, type: type}) //setuj alert
        setTimeout( //uradi reload
          () => window.location.href = "/",
          5000
        )
      }
    }
  
    const displayAlert = () => {
      if(alert.active) return(<RequestAlert text={alert.text} type={alert.type} />);
    }

    const nextStep = () => {
      if(app.system_available.status === "success") {
        return ( 
          <Col xs="12" className="mt-2 justify-content-center align-self-center text-center">
            <button type="button" onClick={() => getNextPage()} className="btn btn-primary py-3 px-3">{Lang.JUMP} <i className="fas fa-long-arrow-alt-right"></i></button>
          </Col>
        )
      }
    }

    return (
      <div className="important-box">
        <div className="box">
        <Header />
          <div id="content" className="content">
            <Row>
              <Col xs="12" className="justify-content-center align-self-center text-center">
                <div className="content-box">
                  <h2 className="mb-1">{Lang.HOME_PRIMARY}</h2>
                  {displayAlert()}
                  <form className="m-0 p-0">
                    <Row className="mt-3">
                      {guestButton()}
                      {buttons()}
                    </Row>
                  </form>
                </div>
              </Col>
              {nextStep()}
            </Row>
          </div>
        </div>
        <a href="/" className="w-100 d-block text-center pt-3 pb-1 close">{Lang.EXIT} <FontAwesomeIcon icon={faSignOutAlt} /></a>
      </div>
    );
  }


export default HomePage;
