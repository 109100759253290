import { Row, Col } from 'reactstrap';
import Header from "../../components/Header";
import * as Lang from '../../lang';


function NotFoundPage() {

  const reload = () => {
    window.location.href  = "/";
  }

  return (
      <div className="box">
       
       <Header />

        <div id="content" className="content">
          <Row>
            <Col xs="12" className="justify-content-center align-self-center text-center">
              <div className="content-box">
                <h1>{Lang.GENERAL_NOT_FOUND}</h1>
                <p className="p-0 intro-paragraf">{Lang.GENERAL_NOT_FOUND_DESCRIPTION}</p>
                <button onClick={() => reload()} className="btn btn-primary p-3">{Lang.RELOAD}</button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
  );
}

export default NotFoundPage;
