import React from "react";
import routes from "./routes";
import { useSelector } from 'react-redux';
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";

import LoginPage from "./pages/Auth/LoginPage";

function App() {
  const isLoggedIn = useSelector((state: any) => state.user.is_logged_in)

  var routesStructure;
  if(isLoggedIn) {
    routesStructure = routes;
  } else {
    routesStructure = [
      { exact:false, path: "/", component: LoginPage }
    ];
  }

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {routesStructure.map((route, idx) => (
            <Route exact={route.exact} path={route.path} component={route.component} key={idx} />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
