import { RestService } from './RestService';
import store from '../reduxState/store';

const apiUrl = process.env.REACT_APP_API_URL;
const state = store.getState();

export const PayOrder = ({order_id, type_id, create_order}:{order_id: number, type_id: number, create_order: boolean}) => {
    const token = state.user.user_token

    const params = {
        "order_id": order_id === 0 ? null : order_id,
        "type_id": type_id,
        "create_order": create_order
    };

    const headerData = {'Authorization': `Bearer ${token}`};

    return new RestService().postMethod(apiUrl + 'pay-order', params, headerData); 
    
};