import { RestService } from './RestService';
import store from '../reduxState/store';

const apiUrl = process.env.REACT_APP_API_URL;
const state = store.getState();

export const TodaysOrder = () => {
    const token = state.user.user_token
    const headerData = {'Authorization': `Bearer ${token}`};
    return new RestService().postMethod(apiUrl + 'todays-order', '', headerData); 
};