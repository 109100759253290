import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import store from '../../reduxState/store';
import { addUserVaucher } from '../../reduxState/action';
import Header from "../../components/Header";
import FoodButton from '../../components/FoodButton';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { DailyOffer } from '../../services/DailyOffer';
import { CreateOrder } from '../../services/CreateOrder';
import RequestAlert from '../../components/RequestAlert';
import * as Lang from '../../lang';
import moment from 'moment';
import { Button, FormGroup, Label, Input } from 'reactstrap';


interface IFood {
  name: string,
  image: string,
  offer_id: number,
  user_image: string,
}

interface IAppState {
  app: {
    user_type_of_work: number,
  },
}

function OrderPage(props: any) {
  moment.locale('bs'); //lang set for moment

  const app = useSelector((state: IAppState) => state.app);
  // const { state } = props.location;
  const [state, setState] = useState(props.location.state);
  // const [mounted, setMounted] = useState(true)

  const [alert, setAlert] = useState({ active: false, text: "", type: "" });
  const [dailyOffer, setDailyOffer] = useState({ status: "failed", food_offer: [] });

  console.log("Date:"  + state.date);
  console.log("Shift:" + state.shift);
  
  // Dan i datum u input-u
  let days = [];
  let daysRequired = 7 //how many days

  for (let i = 1; i <= daysRequired; i++) {
    days.push(moment().add(i, 'days').format('dddd, Do MMMM YYYY'))
  }
  
  const setDateOnPress = ((type: boolean) => {
      if (state.date <= 6)
      {
          setState({
              'date': state.date + 1,
              'shift': state.shift
          })
    }
  });

  const setShiftOnPress = ((shift: number) => {
    setState({
      'date': state.date,
      'shift': shift
    })
  })

  const shiftThree = () => {
    if (app.user_type_of_work !== 3) {
      return (
        <FormGroup check className="form-check-inline" onClick={() => setShiftOnPress(3)}>
          <Input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="3" />
          <Label className="form-check-label" for="inlineRadio3">{Lang.SELECT_ORDER_SHIFT} 3</Label>
        </FormGroup>
      )
    }
  }

  useEffect(() => {
    if (state.shift === 3 || (app.user_type_of_work === 3 && state.shift === 2)) {//smjena 3 nema mogućnost narudžbe | korisnik 12/24 u smjeni 2 nema mogućnost narudžbe
      doAlert(true, Lang.ORDER_SHIFT_ALERT, "danger", false)
      setDailyOffer({ status: 'failed', food_offer: [] })
    } else {
      getDalyOffer()
    }
    // return () => { setMounted(false) } // Zakomentarisano provjera???
    // eslint-disable-next-line
  }, [state.date, state.shift])

  const getDalyOffer = () => {
    DailyOffer(state.date).then(res => {
      console.log("RES:" + res.status);
      if (res.status === 'success')
      {
        // Alert ako smjena nije odabrana
        if (state.shift === 0)
        {
          doAlert(true, Lang.SELECT_SHIFT, "danger", false)
        } else {
          doAlert(false, "", "", false)
          setDailyOffer({ status: res.status, food_offer: res.food_offer })
        }
      
      } else if (res.status === 'failed') {
        // Ima narudžba ne prikazuj ponudu
        setDailyOffer({ status: res.status, food_offer: [] })
        doAlert(true, res.error_msg, "danger", false)
      } else {
        doAlert(true, Lang.GENERAL_SERVICE_ERROR, "danger", true)
      }
    });
  }

  const goOrder = (offer_id: number) => {
    setDailyOffer({ ...dailyOffer, status: "failed" })

    CreateOrder(offer_id, state.date, state.shift).then(res => {
      if (res.status === 'success') {
        doAlert(true, Lang.GENERAL_SUCCESS_ORDER_TOMORROW, "success", true)
        store.dispatch(addUserVaucher(res.voucher_sum))
      } else {
        doAlert(true, Lang.GENERAL_SERVICE_ERROR, "danger", true)
      }
    });
  }

  const replacmentMeal = () => {
    return (
      <FoodButton
        status="success"
        col="12"
        image={false}
        name={Lang.SUBSTITUTE_MEAL}
        image_file=""
        action={() => goOrder(0)}  //šaljem 0 a u servisu istu pretvaram u null
      />
    )
  }

  const doAlert = (active: boolean, text: string, type: string, reload: boolean) => {
    if (!reload) {
      setAlert({ active: active, text: text, type: type }) //setuj alert bez reloada
    } else {
      setAlert({ active: active, text: text, type: type }) //setuj alert i odradi reload
      setTimeout( //resetuj alert nakon 3 sekunde
        () => window.location.href = "/",
        3000
      );
    }
  }

  const displayAlert = () => {
    if (alert.active) return (<RequestAlert text={alert.text} type={alert.type} />);
  }

  return (
    <div className="important-box">
      <div className="box">

        <Header />

        <div id="content" className="content">
          <Row>
            <Col xs="12" className="justify-content-center align-self-center text-center">
              <div className="content-box-select">
                <Row>
                    <Col xs="9">
                      <FormGroup>
                        <Input type="select" name="dateSelect" id="dateSelect" className="form-control-lg" value={state.date} onChange={() => console.log(state.date)}>
                          <option value="0">{Lang.SELECT_ORDER_DATE_DEFAULT}</option>
                          {days.map((day, index) =>
                            <option key={index} value={index + 1}>{day} {(app.user_type_of_work === 1 || app.user_type_of_work === 4 ? '(Smjena 1)' : '')}</option>
                          )}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col xs="3">
                        <Button color="danger" className="w-100 h-100" onClick={() => setDateOnPress(true)}>{Lang.NEXT}</Button>
                    </Col> 
                    <Col xs="12">
                      <FormGroup tag="fieldset" className={"mt-4 " + (app.user_type_of_work === 1 || app.user_type_of_work === 4 ? 'd-none' : '')}>
                        <FormGroup check className="form-check-inline" onClick={() => setShiftOnPress(1)}>
                          <Input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="1" />
                          <Label className="form-check-label" for="inlineRadio1">{Lang.SELECT_ORDER_SHIFT} 1</Label>
                        </FormGroup>
                        <FormGroup check className="form-check-inline" onClick={() => setShiftOnPress(2)}>
                          <Input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="2" />
                          <Label className="form-check-label" for="inlineRadio2">{Lang.SELECT_ORDER_SHIFT} 2</Label>
                        </FormGroup>
                        {shiftThree()}
                      </FormGroup>
                    </Col>
                </Row>
                {displayAlert()}
                </div>
                <div className="content-box">
                <form>
                  <Row className="mt-3">
                    {dailyOffer.status === "success" ? dailyOffer.food_offer.map((food: IFood, index: number) => {
                      return (<FoodButton
                        key={index}
                        status={dailyOffer.status}
                        col={dailyOffer.food_offer.length > 1 ? "6" : "12"}
                        image={true}
                        name={food.name}
                        image_file={food.image}
                        action={() => goOrder(food.offer_id)}
                      />
                      )
                    })
                      : null}
                    {dailyOffer.status === "success" ? replacmentMeal() : null}
                  </Row>
                </form>
              </div>
            </Col>
            {/* <Col xs="12" className="mt-2 justify-content-center align-self-center text-center">
               {!alert.active ? <a href="/" className="btn btn-primary py-3 px-3">{Lang.JUMP_CLOSE} <i className="fas fa-long-arrow-alt-right"></i></a> : null }
              </Col> */}
          </Row>
        </div>
      </div>
      <a href="/" className="w-100 d-block text-center pt-3 pb-1 close">{Lang.EXIT} <FontAwesomeIcon icon={faSignOutAlt} /></a>
    </div>
  );
}

export default OrderPage;
