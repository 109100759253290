import { RestService } from './RestService';
import store from '../reduxState/store';

const apiUrl = process.env.REACT_APP_API_URL;
const state = store.getState();

export const CreateOrder = (offer_id: number, date: number, shift: number) => {
    const token = state.user.user_token

    const params = {
        "offer_id": offer_id !== 0 ? offer_id : null,
        "day": date,
        "shift": shift
    };

    const headerData = {'Authorization': `Bearer ${token}`};

    return new RestService().postMethod(apiUrl + 'create-order', params, headerData); 
    
};