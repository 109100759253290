import React, { useState, useEffect} from 'react';
import Header from "../../components/Header";
import { Row, Col } from 'reactstrap';
import store from '../../reduxState/store';
import { 
  addUserToken, 
  toggleLoggedIn, 
  addUserName, 
  addUserImage, 
  addUserVaucher, 
  addUserPurchaseStatus, 
  addSystemAvailable, 
  addUserShift,
  addUserTypeOfWork
} from '../../reduxState/action';
import { useHistory } from "react-router-dom";
import { Login } from '../../services/AuthServices';
import RequestAlert from '../../components/RequestAlert';
import * as Lang from '../../lang';

function LoginPage() {
  const history = useHistory();

  const [cardID, setCardID] = useState<string>('');
  const [alert, setAlert] = useState({ active: false, text: "", type: "" });

  const handleOnChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    setCardID(event.target.value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => runLogin(cardID), 1000); //ako kucanje ne traje više od sekundu pokreni login
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, [cardID]);

  const runLogin = (cardID: string) => {
    if(!cardID) return //ako je cardID prazan u početnom trenutku spriječi pokušaj logina
    const decCardID = parseInt(cardID, 16); //hextodec (čitač kartice daje hex a nama je podreban dec)

    Login(decCardID).then(res => { //pokreni servis za login
      if (res.status === 'success') {
        storeData(res)
        console.log(res.token_data)
        history.push('/home') //idi na homePage
      } else if(res.status === 'failed') { 
        doAlert(true, res.error_msg, "danger")
      } else { 
        doAlert(true, Lang.GENERAL_SERVICE_ERROR, "danger")
      }
    });
  }

  const storeData = (response: { 
    token_data: string; 
    fullname: string; 
    image: string; 
    voucher_sum: number; 
    purchase_status: object; 
    purchase_available: boolean; 
    purchase_time: string; 
    system_available: object;
    shift: number;
    type_of_work: number;
  }) => {
    store.dispatch(addUserToken(response.token_data))
    store.dispatch(addUserName(response.fullname))
    store.dispatch(addUserImage(response.image)) 
    store.dispatch(addUserVaucher(response.voucher_sum))
    store.dispatch(addUserPurchaseStatus(response.purchase_status))
    store.dispatch(addUserShift(response.shift))
    store.dispatch(addUserTypeOfWork(response.type_of_work))
    // ---------------- //
    store.dispatch(addSystemAvailable(response.system_available))
    store.dispatch(toggleLoggedIn(true))
  }

  const doAlert = (active: boolean, text: string, type: string) => {
    setAlert({active: active, text: text, type: type}) //setuj alert
    setCardID('') //resetuj cardID vrijednost

    setTimeout( //resetuj alert nakon 3 sekunde
      () => setAlert({active: false, text: "", type: ""}),
      5000
    );
  }

  const displayAlert = () => {
    if(alert.active) return(<RequestAlert text={alert.text} type={alert.type} />);
  } 

  return (
    <div className="box">
      
      <Header />

      <div id="content" className="content">
        <Row>
          <Col xs="12" className="justify-content-center align-self-center text-center">
            <div className="content-box position-relative"> 
              <input onChange={handleOnChange} autoFocus style={{opacity: 0, position: 'absolute', left: 0, height: '100%', width: '100%'}} value={cardID} />  
              <h1>{Lang.LOGIN_PRIMARY}</h1>
              {displayAlert()}
              <p className="p-0 intro-paragraf">{Lang.LOGIN_DESCRIPTION}</p>
              <img alt="" src="./img/scan-card.png" width="230" className="mt-3" />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default LoginPage;
